import axios from "axios";
import { baseUrl } from "../config/Base";
export const doctorList = async (searchQuery) => {
    const token = localStorage.getItem("token");
    console.log(searchQuery, " Data is Coming Broooo")
    try {
        if (searchQuery.search) {

            return await axios.get(`${baseUrl}getAllDoctors?page=${searchQuery.page}&limit=${searchQuery.limit}&searchQuery=${searchQuery.search}&filterDays=${searchQuery.filterday}`, {
                headers: {
                    authorization: `${token}`,
                },
            }
            );
        }

        else {
            return await axios.get(`${baseUrl}getAllDoctors?page=${searchQuery.page}&limit=${searchQuery.limit}&filterDays=${searchQuery.filterday}`, {
                headers: {
                    authorization: `${token}`,
                },
            });
        }
    } catch (error) {

    }


};
export const updateStatus = async (userid, status) => {
    console.log(userid, status, "data==============data.data.status=======>")
    const user = {
        userId: userid,
        status: status
    }


    const token = localStorage.getItem("token");
    try {
        return await axios.put(`${baseUrl}updateStatus`, user, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

    } catch (error) {

    }

}

export const doctorView = async (id) => {

    const token = localStorage.getItem("token");
    try {
        return await axios.get(`${baseUrl}doctorDetails/${id}`, {
            headers: {
                authorization: `${token}`,
            },
        });

    } catch (error) {

    }

}

export const deleteUser = async (userid) => {

    console.log(userid, "data==============data.data.status=======>")

    const token = localStorage.getItem("token");
    try {
        return await axios.delete(`${baseUrl}deleteuser/${userid}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

    } catch (error) {

    }

}

export const pendingRequestsList = async (searchQuery) => {

    // console.log(userid, "data==============data.data.status=======>")

    const token = localStorage.getItem("token");
    try {
        return await axios.get(`${baseUrl}getAllPendingDoctorRequests?page=${searchQuery.page}&limit=${searchQuery.limit}`, {
            headers: {
                authorization: `${token}`,
            },
        });

    } catch (error) {

    }

}
export const approveOrRejectRequest = async (userid,status) => {

    console.log(userid, status,"data==============data.data.status=======>")

    const token = localStorage.getItem("token");
    const user = {
        "approve": status,
    }
    try {
        return await axios.post(`${baseUrl}approveOrRejectDoctorRequest/${userid}`,user, {
            headers: {
                authorization: `${token}`,
            },
        });

    } catch (error) {

    }

}

