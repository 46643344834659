import React, { useState } from 'react';
import './ReportComplaint.css';

const ReportComplaint = () => {
  const [activeScreen, setActiveScreen] = useState('NewsComplaints'); // Toggle between screens
  const [searchTerm, setSearchTerm] = useState(''); // Search term

  // Dummy user data
  const users = [
    { id: 1, userId: '1234', name: 'Khaled Anwar', email: 'roberto12@gmail.com', date: '12 July 2024', complaint: 'Lorem ipsum dolor sit amet...Lorem ipsum dolor sit amet', },
    { id: 2, userId: '1234', name: 'John Smit', email: 'roberto12@gmail.com', date: '12 July 2024', complaint: 'Lorem ipsum dolor sit amet...Lorem ipsum dolor sit amet', },
    { id: 3, userId: '1234', name: 'Karda Deo', email: 'roberto12@gmail.com', date: '12 July 2024', complaint: 'Lorem ipsum dolor sit amet...Lorem ipsum dolor sit amet', },
    { id: 4, userId: '1234', name: 'John Smit', email: 'roberto12@gmail.com', date: '12 July 2024', complaint: 'Lorem ipsum dolor sit amet...Lorem ipsum dolor sit amet', },
    { id: 5, userId: '1234', name: 'Karda Deo', email: 'roberto12@gmail.com', date: '12 July 2024', complaint: 'Lorem ipsum dolor sit amet...Lorem ipsum dolor sit amet', },
    { id: 6, userId: '1234', name: 'John Smit', email: 'roberto12@gmail.com', date: '12 July 2024', complaint: 'Lorem ipsum dolor sit amet...Lorem ipsum dolor sit amet', },
    { id: 7, userId: '1234', name: 'Roberto Jain', email: 'roberto12@gmail.com', date: '12 July 2024', complaint: 'Lorem ipsum dolor sit amet...Lorem ipsum dolor sit amet', },
    { id: 8, userId: '1234', name: 'John Smit', email: 'roberto12@gmail.com', date: '12 July 2024', complaint: 'Lorem ipsum dolor sit amet...Lorem ipsum dolor sit amet', },
  ];

  // Filtered user list based on search term
  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="app-container">
      <div className="header">
        <button
          className={activeScreen === 'NewsComplaints' ? 'active' : ""}
          onClick={() => setActiveScreen('NewsComplaints')}

        >
          News complaints
        </button>
        <button
          className={activeScreen === 'History' ? 'active' : ''}
          onClick={() => setActiveScreen('History')}
        >
          History
        </button>
      </div>

      {/* Search Bar */}
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* User Table */}
      {activeScreen === 'NewsComplaints' && (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>User ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Date</th>
                <th>Complaint</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user, index) => (
                <tr key={user.id} style={index % 2 === 0 ? styles.rowOdd : styles.rowEven}>
                  <td>{index + 1}</td>
                  <td>{user.userId}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.date}</td>
                  <td>{user.complaint}</td>
                  <td>
                    <button className="mark-complete-btn">Mark as completed</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination example */}
          <div style={styles.pagination}>
            <span style={styles.paginationSpan}>8 of 100 results</span>
            <button style={styles.paginationButton}>&lt;</button>
            <button style={styles.paginationButton}>&gt;</button>
          </div>
        </div>
      )}

      {activeScreen === 'History' && (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>S.No.</th>
                <th>User ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Date</th>
                <th>Complaint</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user, index) => (
                <tr key={user.id} style={index % 2 === 0 ? styles.rowOdd : styles.rowEven}>
                  <td>{index + 1}</td>
                  <td>{user.userId}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.date}</td>
                  <td>{user.complaint}</td>
                  <td>{user.complaint}</td>
                  {/* <td>
                    <button className="mark-complete-btn">Mark as completed</button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination example */}
          <div style={styles.pagination}>
            <span style={styles.paginationSpan}>8 of 100 results</span>
            <button style={styles.paginationButton}>&lt;</button>
            <button style={styles.paginationButton}>&gt;</button>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  rowEven: {
    backgroundColor: '#f9f9f9',
  },
  rowOdd: {
    backgroundColor: '#fff',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '10px'
  },
  paginationSpan: {
    fontSize: '14px',
  },
  paginationButton: {
    padding: '8px 16px',
    marginLeft: '5px',
    cursor: 'pointer',
  },
};

export default ReportComplaint;
