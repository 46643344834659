
import axios from "axios";
import { baseUrl } from "../config/Base";

export const ListAllCredits = async () => {
  const token = localStorage.getItem("token"); 
  return axios
    .get(`${baseUrl}listAllCredits`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      console.log(res);
      if (res && res.data) {
        return res;
      }
    })
};

export const UpdateCredit = async (userObj) => {
    const token = localStorage.getItem("token"); 
    return axios
      .post(`${baseUrl}updateCredit`, userObj,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res && res.data) {
          return res;
        }
      })
  };

  