import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Edit from '../../assets/image/pencil.png'
import Spinnerr from "../../Components/Spinner/Spinner";
import JoditEditor from "jodit-react";
import { GetCMsByType, UpdateCMS } from '../../ApiComponent/CMSApi';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
function DoctorTerm_Conditions({role}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState("hjkhjkhjkhjkhlkjnkhjnikh");
    const [id, setId] = useState("")
    const [selectedButton, setSelectedButton] = useState("save"); 

    const navigate=useNavigate()
    const [newData, setNewData] = useState({       
        description: ""
    });
    const type = {
        type:"Terms",
        role:"Doctor"
    }
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        getData()

    }, [])

    const getData = () => {
        setLoading(true);
        GetCMsByType(type)
            .then((res) => {
                console.log("cmsdiscripition===============>",res.data.data[0].description)
                if (res?.status === 200) {
                    const decodedDescription = decodeHtmlEntities(res?.data?.data[0]?.description);
                    setLoading(false);
                    setData(decodedDescription);
                    setId(res?.data?.data[0]?._id);
                } else {
                    throw new Error('Failed to fetch data');
                }
            })
            .catch((err) => {
                setLoading(false);
                setError(true);
                // toast.error("Token Changed.......");
                // localStorage.clear("token")
                // navigate("/")
                
                console.error("API fetch error:", err);
            });
    };
    function decodeHtmlEntities(str) {
        const txt = document.createElement('textarea');
        txt.innerHTML = str;
        return txt.value;
    }

    const HtmlRenderer = (htmlContent) => {
        const decodedHtml = decodeHtmlEntities(htmlContent);

        return (
        <div className=' text-black' dangerouslySetInnerHTML={{ __html: decodedHtml }} />
        );
    };

    const handleSave = () => {
        const trimmedDescription = newData.description.trim();
        console.log(trimmedDescription,"dataaaa")
        console.log(trimmedDescription.length,"dataaaa")
        // if (trimmedDescription.length === 0) {
        //     toast.error("Terms and Conditions can't be blank or whitespace.");
        //     setIsEditing(true);
        // } else {
            setLoading(true);
            UpdateCMS({ ...newData, description: trimmedDescription })
                .then((res) => {
                    if (res.status === 200) {
                        getData();
                        setLoading(false);
                        setIsEditing(false);
                        toast.success("Terms and Conditions updated");
                    } else {
                        throw new Error('Failed to update data');
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    setError(true);
                    console.error("API update error:", err);
                });
        // }
    };


    return (
        <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                <h1 className=" text-4xl font-sfpdBold text-white text-center">SOME  ERROR  OCCURED</h1>
            ) : (
                <div>
                    {isEditing ? (
                        <>

                            <div className="card-head p-0 text-end gap-2 flex justify-end mb-4">
                                {/* <Link
                                    href="#"
                                    className="bg-blue-500 px-5 py-2 rounded-lg  text-gray-50 "
                                    onClick={() => setIsEditing(false)} // Cancel editing
                                >
                                    Cancel
                                </Link> */}
                                 <Button
                                    className={`w-[130px] px-0 rounded-0 text-xs custom-btn-doctor ${selectedButton === 'cancel' ? 'selected' : ''}`}
                                    onClick={() => {
                                        setSelectedButton('cancel');
                                        setIsEditing(false)
                                    }}
                                >
                                    Cancel
                                </Button>
                                {/* <Link
                                    href="#"
                                    className=" px-5 py-2 rounded-lg  bg-orange-600 text-gray-50"
                                    onClick={handleSave} // Save changes
                                >
                                    Save
                                </Link> */}
                                 <Button
                                    className={`w-[130px] px-0 rounded-0 text-xs custom-btn-doctor ${selectedButton === 'save' ? 'selected' : ''}`}
                                    onClick={() => {
                                        setSelectedButton('save');  // Set the selected button state
                                        handleSave()
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                            <JoditEditor
                                className="textji"
                                value={(data)}
                                onChange={(text) => setNewData({ ...newData, description: text, id: id })}
                            />

                        </>
                    ) : (
                        <>
                            <Link onClick={() => setIsEditing(true)} className='absolute right-3 -top-12  rounded-full w-9 h-9 bg-[rgba(255,255,255,.3)] inline-flex items-center !border-[#000000] justify-center overflow-hidden border cursor-pointer'>

                                <img src={Edit} className='w-4 h-4'
                                />
                            </Link>
                            {/* <p className="textji">{data}</p> */}
                            <div className='bg-[#e5e5e5] text-black p-5 [&_p]:mb-2 '  >{HtmlRenderer(data)}</div>




                        </>
                    )}
                </div>
            )}

        </>
    )
}

export default DoctorTerm_Conditions