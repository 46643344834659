import React from "react";
import { Link } from "react-router-dom";

function Pagination({
  data,
  indexNumber,
  setPage,
  totalPage,
  page,
  total,
  paginationHandler,
}) {
  return (
    < div className=" flex justify-between">
    {data && data.length > 0 && (
          <div className="dataTables_info">
            Showing {indexNumber + 1} to {data && indexNumber + data.length} of{" "}
            {total} entries
          </div>
      )}
    {
      data && data.length > 0 && 
      <ul className="pagination mb-0">
      <li className="paginate_button page-item previous">
        <Link className="page-link" onClick={(e) => {
                  if (page > 1) {
                    paginationHandler(e, page - 1);
                  }
                }} >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M0 0h24v24H0V0z"></path>
            <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path>
          </svg>
        </Link>
      </li>
      <div className="text-muted total-text d-flex align-items-center fw-bold">
      &nbsp;
              <span className=" text-slate-300">
                {page} of {totalPage}
              </span>
              &nbsp;
      </div>
      <li className="paginate_button page-item next">
        <Link className="page-link" onClick={(e) => {
                  console.log("page >= totalPage", page >= totalPage);
                  if (page < totalPage) {
                    paginationHandler(e, page + 1);
                  }
                }} >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M0 0h24v24H0V0z"></path>
            <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
          </svg>
        </Link>
      </li>
    </ul>
    }
  </div>
  );
}

export default Pagination;
