import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Spinnerr = () => {
    return (
        <>
            <div className=' w-[100%] py-64 flex justify-center  items-center'>
                <Spinner variant="dark" animation="grow" size="lg"  />
            </div>
        </>
    );
}

export default Spinnerr;
