import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import BetCreation from "./Pages/BetCreation/BetCreation";
import DoctorManagment from "./Pages/BetCreation/DoctorManagment";
import ViewBetCreation from "./Pages/BetCreation/ViewBetCreation";
import BetOrdersManagement from "./Pages/BetOrdersManagement/BetOrdersManagement";
import CreditsManagement from "./Pages/CreditsManagement/CreditsManagement";
import CMSManagement from "./Pages/CMSManagement/CMSManagement";
import ChangePassword from "./Pages/ChangePassword/ChangePassword";
import { ToastContainer } from "react-toastify";
import NewRequests from "./Pages/BetCreation/NewRequests";
import AddUser from "./Pages/UserManagement/AddUser";
import EditUser from "./Pages/UserManagement/EditUser";
import ReportComplaint from "./Pages/ReportComplaint/ReportComplaint";
import NotificationManagement from "./Pages/NotificationManagement/NotificationManagement";
import 'react-toastify/dist/ReactToastify.css';
import White_paper from "./Pages/CMSManagement/White_Paper";

const Layout = React.lazy(() => import("./Components/Layout/Layout"));
const Spinnerr = React.lazy(() => import("./Components/Spinner/Spinner"));
const Login = React.lazy(() => import("./Pages/Login/Login"));
const UpdatePassword = React.lazy(() => import("./Pages/Login/UpdatePassword"));
const ResetPassword = React.lazy(() => import("./Pages/Login/ResetPassword"));
const Dashboard = React.lazy(() => import("./Pages/Dashboard/Dashboard"));
const UserManagement = React.lazy(() => import("./Pages/UserManagement/UserManagement"));
const UpdateProfile = React.lazy(() => import("./Pages/UpdateProfile/UpdateProfile"));
const PendingRequest= React.lazy(()=>import('./Pages/BetCreation/pendingrequest'))

const ViewUser = React.lazy(() => import("./Pages/UserManagement/ViewUser"));

const AuthLayout = React.lazy(() => import("./Components/Layout/AuthLayout"));


function App() {
  return (
    <>
      <Suspense fallback={<Spinnerr />}>
        <Routes >
          <Route path="/" element={<AuthLayout />}>
            <Route index element={<Login />} />
            <Route path="/update-password" element={<UpdatePassword />} />
            <Route path="/admin/reset-password" element={<ResetPassword />} />
          </Route>
          <Route element={<Layout />} >
            <Route index path="/dashboard" element={<Dashboard />} />
            <Route path="/user-management" element={<UserManagement />} />
            <Route path="/user-management/:_id" element={<ViewUser />} />
            <Route path="/bet-creation" element={<BetCreation />} />
            <Route path="/Doctor-Management" element={<DoctorManagment />} />
            <Route path="/Doctor-Management/:_id" element={<ViewBetCreation />} />
            <Route path="/bet-orders-management" element={<BetOrdersManagement />} />
            <Route path="/credits-management" element={<CreditsManagement />} />
            <Route path="/cms-management" element={<CMSManagement />} />
            <Route path="/new-requests/:id" element={<PendingRequest/>} />
            {/* <Route path="/change-password" element={<ChangePassword />} /> */}
            <Route path="/Profile" element={<ChangePassword />} />
            <Route path="/update-profile" element={<UpdateProfile />} />
            <Route path="/new-requests" element={<NewRequests />} />
            {/* <Route path="/Doctor-Managment" element={<NewRequests />} /> */}
            <Route path="/Add-User" element={<AddUser />} />
            <Route path="/Edit-User/:_id" element={<EditUser />} />
            <Route path="/notification-management" element={<NotificationManagement />} />
            <Route path="/report-complaint" element={<ReportComplaint />} />
       
          </Route>
        </Routes>
        <ToastContainer />
      </Suspense>
    </>
  );
}

export default App;
