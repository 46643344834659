import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Edit from '../../assets/image/pencil.png';
import Spinnerr from "../../Components/Spinner/Spinner";
import JoditEditor from "jodit-react";
import { GetCMsByType, UpdateCMS } from '../../ApiComponent/CMSApi';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';

function Privacy_Policy({role}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState("");
    const [id, setId] = useState("");
    const [selectedButton, setSelectedButton] = useState("save"); 

    const [newData, setNewData] = useState({
        description: ""
    });
    const type = {
        type: "Privacy",
        role: 'User'
    }
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        getData();
    }, []);


    const getData = () => {
        setLoading(true);
        GetCMsByType(type)
            .then((res) => {
                if (res?.status === 200) {
                    const decodedDescription = decodeHtmlEntities(res?.data?.data[0]?.description);
                    setLoading(false);
                    setData(decodedDescription);
                    setId(res?.data?.data[0]?._id);
                } else {
                    throw new Error('Failed to fetch data');
                }
            })
            .catch((err) => {
                setLoading(false);
                setError(true);

                console.error("API fetch error:", err);
            });
    };

    const handleSave = () => {
        if (newData.description.length <= 0) {
            toast.error("Privacy Policy Can't Be Blank..")
            setIsEditing(true)
        }
        else if (newData.description.length > 0) {
            setLoading(true);
            UpdateCMS(newData)
                .then((res) => {
                    if (res.status === 200) {
                        getData();
                        setLoading(false);
                        setIsEditing(false);
                        toast.success("Privacy Policy Updated");
                    } else {
                        throw new Error('Failed to update data');
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    setError(true);
                    toast.error("An error occurred while updating data");
                    console.error("API update error:", err);
                });
        }
    };
    function decodeHtmlEntities(str) {
        const txt = document.createElement('textarea');
        txt.innerHTML = str;
        return txt.value;
    }

    const HtmlRenderer = (htmlContent) => {
        const decodedHtml = decodeHtmlEntities(htmlContent);

        return (
            <div className=' text-black' dangerouslySetInnerHTML={{ __html: decodedHtml }} />
        );
    };
    return (
        <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                <h1 className=" text-4xl font-sfpdBold text-white text-center">SOME ERROR OCCURED</h1>
            ) : (
                <div>
                    {isEditing ? (
                        <>
                            <div className="card-head p-0 text-end gap-2 flex justify-end mb-4">
                                {/* <Link
                                    href="#"
                                    className="bg-blue-500 px-5 py-2  text-gray-50"
                                    onClick={() => setIsEditing(false)} // Cancel editing
                                >
                                    Cancel
                                </Link> */}
                                <Button
                                    className={`w-[130px] px-0 rounded-0 text-xs custom-btn-doctor ${selectedButton === 'cancel' ? 'selected' : ''}`}
                                    onClick={() => {
                                        setSelectedButton('cancel');
                                        setIsEditing(false)
                                    }}
                                >
                                    Cancel
                                </Button>
                                {/* <Link
                                    href="#"
                                    className="px-5 py-2  bg-orange-600 text-gray-50"
                                    onClick={handleSave} // Save changes
                                >
                                    Save
                                </Link> */}
                                <Button
                                    className={`w-[130px] px-0 rounded-0 text-xs custom-btn-doctor ${selectedButton === 'save' ? 'selected' : ''}`}
                                    onClick={() => {
                                        setSelectedButton('save');  // Set the selected button state
                                        handleSave()
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                            <JoditEditor
                                className="textji"
                                value={newData.description || data}
                                onChange={(text) => setNewData({ ...newData, description: text, id: id })}
                            />
                            
                        </>
                    ) : (
                        <>
                            <Link
                                onClick={() => setIsEditing(true)}
                                className='absolute right-3 -top-12 rounded-full w-9 h-9 bg-[rgba(255,255,255,.3)] inline-flex items-center !border-[#000000] justify-center overflow-hidden border cursor-pointer'
                            >
                                <img src={Edit} className='w-4 h-4' />
                            </Link>

                            <div className='bg-[#e5e5e5] text-black p-5 [&_p]:mb-2'>
                                {HtmlRenderer(data)}
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
}

export default Privacy_Policy;
