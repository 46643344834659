import axios from "axios";
import { baseUrl } from "../config/Base";

export const ChangePasswordApi = async (userObj,id) => {
  console.log("================>",userObj)
  console.log("================>",id)
  const token = localStorage.getItem("token"); // Get the token from local storage
  return axios
    .put(`${baseUrl}changePassword`, userObj, {
      headers: {
        authorization: `${token}`, // Add the bearer token to the headers
      },
    })
    .then((res) => {
      console.log(res);
      if (res && res.data) {
        return res;
      }
    })
};