import axios from "axios";
import { baseUrl } from "../config/Base";
export const UpdateCMS = (newData) => {
    console.log("newupdateCMsManagementData", newData)
    console.log("iiiiiidddddddd", newData.id)
    const token = localStorage.getItem("token");
    const myheader = {
        "userId": localStorage.getItem('userId'),
        "authorization": token
    }
    const HeaderToken = {
        headers: myheader
    }

    return axios
        .put(`${baseUrl}updateCMsManagement/${newData.id}`, newData, HeaderToken)
        .then(res => {
            console.log(res)
            if (res && res.data) {


                return res;
            }
        })
        .catch(err => {
            console.error(err);
        })



}
export const GetCMsByType = (type) => {
    const token = localStorage.getItem("token");
    const queryString = new URLSearchParams(type).toString();
    console.log("=============cmstoken=====", queryString)
    const myheader = {
        // "userId":localStorage.getItem('userId'),
        Authorization: token
    }
    const HeaderToken = {
        headers: myheader
    }

    return axios
        .get(`${baseUrl}getCMsManagement?${queryString}`, HeaderToken)
        .then(res => {

            if (res && res.data) {


                return res;
            }
        })
        .catch(err => {
            console.error(err);
        })



}
export const GetFAQ = (role) => {
    const myheader = {
        "userId": localStorage.getItem('userId'),
        "Authorization": localStorage.getItem('token')
    }
    const HeaderToken = {
        headers: myheader
    }

    return axios
        .get(`${baseUrl}getFaqList?role_wise=${role}`, HeaderToken)
        .then(res => {
            if (res && res.data) {
                return res;
            }
        })
        .catch(err => {
            console.error(err);
        })



}
export const AddFAQ = (body) => {
    const myheader = {
        "userId": localStorage.getItem('userId'),
        "Authorization": localStorage.getItem('token')
    }
    const HeaderToken = {
        headers: myheader
    }

    return axios
        .post(`${baseUrl}addFaq`, body, HeaderToken)
        .then(res => {

            if (res && res.data) {


                return res;
            }
        })
        .catch(err => {
            console.error(err);
        })



}
export const UpdateFAQ = (newData) => {
    const myheader = {
        "userId": localStorage.getItem('userId'),
        "Authorization": localStorage.getItem('token')
    }
    const HeaderToken = {
        headers: myheader
    }

    return axios
        .post(`${baseUrl}updateFaq`, newData, HeaderToken)
        .then(res => {
            console.log(res)
            if (res && res.data) {


                return res;
            }
        })
        .catch(err => {
            console.error(err);
        })



}

export const DeleteFAQ=(newData)=>{
    const myheader = {
        "userId": localStorage.getItem('userId'),
        "Authorization": localStorage.getItem('token')
    }
    const HeaderToken = {
        headers: myheader
    }

    return axios
        .delete(`${baseUrl}deleteFaq/${newData}`, HeaderToken)
        .then(res => {
            console.log(res)
            if (res && res.data) {


                return res;
            }
        })
        .catch(err => {
            console.error(err);
        })
}