// import React, { useState } from "react";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import { Link, useNavigate } from "react-router-dom";
// import { FaEyeSlash, FaEye } from "react-icons/fa";
// import { ChangePasswordApi } from "../../ApiComponent/ChangePasswordApi";
// import { toast } from "react-toastify";
// import Modal from 'react-bootstrap/Modal';


// const ChangePassword = () => {


//   const [verification, setVerification] = useState(false);
//   const VerificationShow = () => setVerification(true);
//   const [password, setPassword] = useState(false);
//   const [password2, setPassword2] = useState(false);
//   const [password3, setPassword3] = useState(false);

//   const showPassoword = () => setPassword(!password);
//   const showPassoword2 = () => setPassword2(!password2);
//   const showPassoword3 = () => setPassword3(!password3);


//   const [update, setUpdate] = useState(false);
//   const updateClose = () => setUpdate(false);
//   const updateShow = (e) => {
//     e.preventDefault();
//     if (!passwordInput.oldPassword) {
//       toast.error("Please enter old password");
//       return;
//     }
//     if (!passwordInput.newPassword) {
//       toast.error("Please enter new password");
//       return;
//     }
//     if (!passwordInput.confirmPassword) {
//       toast.error("Please confirm password");
//       return;
//     }
//     setUpdate(true);
//   }
//   const navigate = useNavigate()
//   const [passwordInput, setPasswordInput] = useState({
//     oldPassword: "",
//     newPassword: "",
//     confirmPassword: "",
//   });

//   const handleClose = () => {
//     setVerification(false);
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
  
//     // Remove leading and trailing spaces from the input value
//     setPasswordInput((prevData) => ({
//       ...prevData,
//       [name]: value.trim(),
//     }));
//   };
  

//   const handleSubmit = async (e) => {
//     e.preventDefault();
  
//     // Trim password inputs to remove leading and trailing whitespace
//     const trimmedPasswords = {
//       oldPassword: passwordInput.oldPassword.trim(),
//       newPassword: passwordInput.newPassword.trim(),
//       confirmPassword: passwordInput.confirmPassword.trim(),
//     };
  
//     if (!trimmedPasswords.oldPassword) {
//       toast.error("Please enter old password");
//       return;
//     }
//     if (!trimmedPasswords.newPassword) {
//       toast.error("Please enter new password");
//       return;
//     }
//     if (!trimmedPasswords.confirmPassword) {
//       toast.error("Please confirm password");
//       return;
//     }
  
//     if (trimmedPasswords.newPassword === trimmedPasswords.oldPassword) {
//       toast.error("Old Password And New Password Can't Be Same");
//       return;
//     }
  
//     if (trimmedPasswords.newPassword !== trimmedPasswords.confirmPassword) {
//       toast.error("Passwords do not match");
//       return;
//     }
  
//     let id = localStorage.getItem("Id");
  
//     try {
//       const res = await ChangePasswordApi(trimmedPasswords, id);
//       if (res?.status === 200) {
//         localStorage.clear("token", "userData");
//         navigate("/");
//         toast.success("Password changed successfully!");
//       } else {
//         toast.error("Something went wrong");
//       }
//     } catch (error) {
//       toast.error(error?.response?.data?.errorMessage);
//       console.log(error, "error");
//     }
//   };
  

//   const handleBackClick = (event) => {
//     event.preventDefault();
//     navigate(-1); // -1 will take the user back to the previous page
//   };

//   return (
//     <>
//       <Row>
//         <Col xl={2}>
//           <Link onClick={handleBackClick} className="border-[1px] px-4 py-1 inline-block text-[black] border-[black]  transition-all">
//             Back
//           </Link>
//         </Col>
//       </Row>
//       <Row>
//         <Col xl={5} className="mx-auto mt-14">
//           <div className=" text-black p-4 [&_p]:mb-2 ">
//             <form className="custom_form">
//               <div className="form-group">
//                 <div class="relative">
//                   <p className="text-xs text-[#9f9f9f] ">Enter Old Password</p>
//                   <input
//                     type={password ? "text" : "password"}
//                     class="block px-2.5 py-2 w-full text-base text-black bg-transparent  outline-1 outline-double outline-gray-400  dark:text-white dark:border-gray-400 dark:focus:border-blue-500 focus:outline-1 focus:ring-0 focus:outline-gray peer"
//                     id="pwd1"
//                     placeholder=""
//                     name="oldPassword"
//                     value={passwordInput?.oldPassword}
//                     onChange={handleInputChange}
//                   />
//                   <div
//                     className="pos-abs hide-show-pass mt-[12px]"
//                     onClick={showPassoword}
//                   >
//                     {password ? <FaEye /> : <FaEyeSlash />}
//                   </div>
//                 </div>
//               </div>
//               <div className="form-group !mt-6">
//                 <div class="relative">
//                   <p className="text-xs text-[#9f9f9f] ">Enter New Password</p>

//                   <input
//                     type={password2 ? "text" : "password"}
//                     class="block px-2.5 py-2 w-full text-base text-black bg-transparent  outline-1 outline-double outline-gray-400  dark:text-white dark:border-gray-400 dark:focus:border-blue-500 focus:outline-1 focus:ring-0 focus:outline-gray peer"
//                     id="pwd2"
//                     placeholder=""
//                     name="newPassword"
//                     value={passwordInput?.newPassword}
//                     onChange={handleInputChange}
//                   />
//                   <div
//                     className="pos-abs hide-show-pass mt-[12px]"
//                     onClick={showPassoword2}
//                   >
//                     {password2 ? <FaEye /> : <FaEyeSlash />}
//                   </div>
//                 </div>
//               </div>
//               <div className="form-group !mt-6">
//                 <div class="relative">
//                   <p className="text-xs text-[#9f9f9f] ">Enter Confirm Password</p>
//                   <input
//                     type={password3 ? "text" : "password"}
//                     class="block px-2.5 py-2 w-full text-base text-black bg-transparent  outline-1 outline-double outline-gray-400  dark:text-white dark:border-gray-400 dark:focus:border-blue-500 focus:outline-1 focus:ring-0 focus:outline-gray peer"
//                     id="pwd3"
//                     placeholder=""
//                     name="confirmPassword"
//                     value={passwordInput?.confirmPassword}
//                     onChange={handleInputChange}
//                   />
//                   <div
//                     className="pos-abs hide-show-pass mt-[12px]"
//                     onClick={showPassoword3}
//                   >
//                     {password3 ? <FaEye /> : <FaEyeSlash />}
//                   </div>
//                 </div>
//               </div>

//               <button

//                 onClick={(e) => updateShow(e)}
//                 className="text-white w-full bg-[#1d1d1c] outline-none focus:outline-none focus:ring-0 font-medium  text-xs px-5 py-2.5 text-center me-2 mb-2  block !mt-8"
//               >
//                 Change Password
//               </button>
//             </form>
//           </div>
//         </Col>
//       </Row>
//       <Modal dialogClassName="modal-custom-sm" centered show={update} onHide={updateClose}>
//         <Modal.Body className='p-0'>
//           <div className="card logout-card common_box">
//             <div className="card-body common_box_body py-4 px-3">
//               <p className="modalTitle text-center mb-2">Are you sure you want to<br />change password?</p>

//               <div className="btn-wrapper text-center">
//                 <button className="btn btn-form btn-cancel" onClick={updateClose}>No</button>
//                 <button className="btn btn-form btn-add" onClick={handleSubmit}>Yes</button>
//               </div>

//             </div>
//           </div>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// };

// export default ChangePassword;

import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useNavigate } from "react-router-dom";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { ChangePasswordApi } from "../../ApiComponent/ChangePasswordApi";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';

const ChangePassword = () => {
  const [verification, setVerification] = useState(false);
  const VerificationShow = () => setVerification(true);
  const [password, setPassword] = useState(false);
  const [password2, setPassword2] = useState(false);
  const [password3, setPassword3] = useState(false);

  const showPassoword = () => setPassword(!password);
  const showPassoword2 = () => setPassword2(!password2);
  const showPassoword3 = () => setPassword3(!password3);

  const [update, setUpdate] = useState(false);
  const updateClose = () => setUpdate(false);
  const updateShow = (e) => {
    e.preventDefault();
    if (!passwordInput.oldPassword) {
      toast.error("Please enter old password");
      return;
    }
    if (!passwordInput.newPassword) {
      toast.error("Please enter new password");
      return;
    }
    if (!passwordInput.confirmPassword) {
      toast.error("Please confirm password");
      return;
    }
    setUpdate(true);
  }

  const navigate = useNavigate();
  const [passwordInput, setPasswordInput] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleClose = () => {
    setVerification(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordInput((prevData) => ({
      ...prevData,
      [name]: value.trim(),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const trimmedPasswords = {
      oldPassword: passwordInput.oldPassword.trim(),
      newPassword: passwordInput.newPassword.trim(),
      confirmPassword: passwordInput.confirmPassword.trim(),
    };

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,14}$/;

    if (!trimmedPasswords.oldPassword) {
      toast.error("Please enter old password");
      return;
    }
    if (!trimmedPasswords.newPassword) {
      toast.error("Please enter new password");
      return;
    }
    if (!passwordRegex.test(trimmedPasswords.newPassword)) {
      toast.error("New password must be 8-14 characters long, and include at least one uppercase letter, one lowercase letter, one digit, and one special character.");
      return;
    }
    if (!trimmedPasswords.confirmPassword) {
      toast.error("Please confirm password");
      return;
    }
    if (trimmedPasswords.newPassword === trimmedPasswords.oldPassword) {
      toast.error("Old Password And New Password Can't Be Same");
      return;
    }
    if (trimmedPasswords.newPassword !== trimmedPasswords.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    let id = localStorage.getItem("Id");

    try {
      const res = await ChangePasswordApi(trimmedPasswords, id);
      if (res?.status === 200) {
        localStorage.clear("token", "userData");
        navigate("/");
        toast.success("Password changed successfully!");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error(error?.response?.data?.errorMessage);
      console.log(error, "error");
    }
  };

  const handleBackClick = (event) => {
    event.preventDefault();
    navigate(-1); // -1 will take the user back to the previous page
  };

  return (
    <>
      <Row>
        <Col xl={2}>
          <Link onClick={handleBackClick} className="border-[1px] px-4 py-1 inline-block text-[black] border-[black]  transition-all">
            Back
          </Link>
        </Col>
      </Row>
      <Row>
        <Col xl={5} className="mx-auto mt-14">
          <div className=" text-black p-4 [&_p]:mb-2 ">
            <form className="custom_form">
              <div className="form-group">
                <div class="relative">
                  <p className="text-xs text-[#9f9f9f] ">Enter Old Password</p>
                  <input
                    type={password ? "text" : "password"}
                    class="block px-2.5 py-2 w-full text-base text-black bg-transparent  outline-1 outline-double outline-gray-400  dark:text-white dark:border-gray-400 dark:focus:border-blue-500 focus:outline-1 focus:ring-0 focus:outline-gray peer"
                    id="pwd1"
                    name="oldPassword"
                    value={passwordInput?.oldPassword}
                    onChange={handleInputChange}
                  />
                  <div
                    className="pos-abs hide-show-pass mt-[12px]"
                    onClick={showPassoword}
                  >
                    {password ? <FaEye /> : <FaEyeSlash />}
                  </div>
                </div>
              </div>
              <div className="form-group !mt-6">
                <div class="relative">
                  <p className="text-xs text-[#9f9f9f] ">Enter New Password</p>

                  <input
                    type={password2 ? "text" : "password"}
                    class="block px-2.5 py-2 w-full text-base text-black bg-transparent  outline-1 outline-double outline-gray-400  dark:text-white dark:border-gray-400 dark:focus:border-blue-500 focus:outline-1 focus:ring-0 focus:outline-gray peer"
                    id="pwd2"
                    name="newPassword"
                    value={passwordInput?.newPassword}
                    onChange={handleInputChange}
                  />
                  <div
                    className="pos-abs hide-show-pass mt-[12px]"
                    onClick={showPassoword2}
                  >
                    {password2 ? <FaEye /> : <FaEyeSlash />}
                  </div>
                </div>
              </div>
              <div className="form-group !mt-6">
                <div class="relative">
                  <p className="text-xs text-[#9f9f9f] ">Enter Confirm Password</p>
                  <input
                    type={password3 ? "text" : "password"}
                    class="block px-2.5 py-2 w-full text-base text-black bg-transparent  outline-1 outline-double outline-gray-400  dark:text-white dark:border-gray-400 dark:focus:border-blue-500 focus:outline-1 focus:ring-0 focus:outline-gray peer"
                    id="pwd3"
                    name="confirmPassword"
                    value={passwordInput?.confirmPassword}
                    onChange={handleInputChange}
                  />
                  <div
                    className="pos-abs hide-show-pass mt-[12px]"
                    onClick={showPassoword3}
                  >
                    {password3 ? <FaEye /> : <FaEyeSlash />}
                  </div>
                </div>
              </div>

              <button
                onClick={(e) => updateShow(e)}
                className="text-white w-full bg-[#1d1d1c] outline-none focus:outline-none focus:ring-0 font-medium  text-xs px-5 py-2.5 text-center me-2 mb-2  block !mt-8"
              >
                Change Password
              </button>
            </form>
          </div>
        </Col>
      </Row>
      <Modal dialogClassName="modal-custom-sm" centered show={update} onHide={updateClose}>
        <Modal.Body className='p-0'>
          <div className="card logout-card common_box">
            <div className="card-body common_box_body py-4 px-3">
              <p className="modalTitle text-center mb-2">Are you sure you want to<br />change password?</p>

              {/* <div className="d-flex justify-content-center"> */}
                {/* <button onClick={updateClose} className="btn btn-outline mx-1">Cancel</button>
                <button className="btn btn-dark mx-1" onClick={handleSubmit}>Confirm</button> */}
               <div className="btn-wrapper text-center">
                                    <Link className="btn btn-form btn-cancel" onClick={updateClose}>Cancel</Link>
                                    <Link className="btn btn-form btn-add" onClick={handleSubmit} >Confirm</Link>
                                </div>
              {/* </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChangePassword;




