import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Sort from '../../assets/image/filter.png'
import Filter from '../../assets/image/filter.png'
import Spinnerr from "../../Components/Spinner/Spinner";
const BetCreation = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const totalPage = 2;
  const [page, setPage] = useState(0);

  const [delet, setDelet] = useState(false);
  const deleteClose = () => setDelet(false);
  const deleteShow = () => setDelet(true);

  const [block, setBlock] = useState(false);
  const blockClose = () => setBlock(false);
  const blockShow = () => setBlock(true);

  const [rejection, setRejection] = useState(false);
  const rejectionClose = () => setRejection(false);
  const rejectionShow = () => setRejection(true);


  const [images, setImages] = useState(false);
  const imagesClose = () => setImages(false);
  const imagesShow = () => setImages(true);

  return (
    <>
     {loading ? (
                <Spinnerr />
            ) : error ? (
               
                <h1 className=" text-4xl font-sfpdBold text-white text-center">SOME  ERROR  OCCURED</h1>
            ) : (<>
      <Tab.Container id="left-tabs-example" defaultActiveKey="Cricket">
        <div className='row align-items-center'>
          <div className="col-md-6 w-full">
            <Nav variant="pills" className="nav-pills-tabs [&_.nav-link]:px-8 [&_.nav-link]:py-2.5 [&_.nav-link]:rounded-lg">
              <Nav.Item><Nav.Link eventKey="Cricket">Cricket</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="Football">Football</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="Tennis">Tennis</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="Basketball">Basketball</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="Rugby">Rugby</Nav.Link></Nav.Item>
            </Nav>
          </div>
        </div>
        <Tab.Content className='mt-4'>
          <Tab.Pane eventKey="Cricket">
            <div className="common-table-wrapper m-0">
              <Row className="mb-4">
                <Col md={8}>
                    <div className='flex gap-3'>
                        <label class="relative block">
                            <span class="sr-only">Search</span>
                            <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                                <svg class="h-5 w-5 fill-slate-300" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                            </span>
                            <input class="placeholder:text-gray-300 block !border border-[#144077] py-2.5 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-base bg-[#144077] w-80" placeholder="Search" type="text" name="Search" />
                        </label>
                        <div className="flex gap-3">
                            <Dropdown className='flex'>
                                <Dropdown.Toggle className='!border min-w-[34px] !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                    <span className=""><img src={Filter} alt="filter" className='h-[16px] filer invert' /></span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                    <Dropdown.Item className='text-sm font-medium'>Last day</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>Last 2 days</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>Last week</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>Last 2 weeks</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>Last month</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown className='flex'>
                              <Dropdown.Toggle className='!border min-w-[34px] !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                  <span><img src={Sort} alt="filter" className='h-[14px] filer invert' /></span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                  <Dropdown.Item className='text-sm font-medium'>Option 1</Dropdown.Item>
                                  <Dropdown.Item className='text-sm font-medium'>Option 2</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown className='flex'>
                                <Dropdown.Toggle className='!border !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] [&_span]:hover:!text-white [&_small]:hover:!text-white flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                    <span className='text-slate-300 transition-all'>Download as </span>
                                    <small className='text-[10px] text-slate-300 ml-2 transition-all'>&#x2BC6;</small>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                    <Dropdown.Item className='text-sm font-medium'>PDF</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>XLS</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </Col>
                <Col md={6}></Col>
              </Row>
              <h2 className='text-[#34ACE1] text-[19px] font-medium'>ICC World Cup</h2>
              <div className='flex flex-nowrap overflow-auto mt-2 gap-4 pb-2 mb-4 slimScroll'>
                <Link to="/view-bet-creation" className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </Link>
                <Link className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </Link>
                <Link className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </Link>
                <Link className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </Link>
                <Link className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </Link>
                <Link className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </Link>
              </div>
              <h2 className='text-[#34ACE1] text-[19px] font-medium'>ICC World Cup</h2>
              <div className='flex flex-nowrap overflow-auto mt-2 gap-4 pb-2 mb-4 slimScroll'>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
              </div>
              <h2 className='text-[#34ACE1] text-[19px] font-medium'>ICC World Cup</h2>
              <div className='flex flex-nowrap overflow-auto mt-2 gap-4 pb-2 mb-4 slimScroll'>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
              </div>
              <h2 className='text-[#34ACE1] text-[19px] font-medium'>ICC World Cup</h2>
              <div className='flex flex-nowrap overflow-auto mt-2 gap-4 pb-2 mb-4 slimScroll'>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="Football">
            <div className="common-table-wrapper m-0">
              <Row className="mb-4">
              <Col md={8}>
                    <div className='flex gap-3'>
                        <label class="relative block">
                            <span class="sr-only">Search</span>
                            <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                                <svg class="h-5 w-5 fill-slate-300" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                            </span>
                            <input class="placeholder:text-gray-300 block !border border-[#144077] py-2.5 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-base bg-[#144077] w-80" placeholder="Search" type="text" name="Search" />
                        </label>
                        <div className="flex gap-3">
                            <Dropdown className='flex'>
                                <Dropdown.Toggle className='!border min-w-[34px] !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                    <span className=""><img src={Filter} alt="filter" className='h-[16px] filer invert' /></span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                    <Dropdown.Item className='text-sm font-medium'>Last day</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>Last 2 days</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>Last week</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>Last 2 weeks</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>Last month</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown className='flex'>
                              <Dropdown.Toggle className='!border min-w-[34px] !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                  <span><img src={Sort} alt="filter" className='h-[14px] filer invert' /></span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                  <Dropdown.Item className='text-sm font-medium'>Option 1</Dropdown.Item>
                                  <Dropdown.Item className='text-sm font-medium'>Option 2</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown className='flex'>
                                <Dropdown.Toggle className='!border !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] [&_span]:hover:!text-white [&_small]:hover:!text-white flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                    <span className='text-slate-300 transition-all'>Download as </span>
                                    <small className='text-[10px] text-slate-300 ml-2 transition-all'>&#x2BC6;</small>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                    <Dropdown.Item className='text-sm font-medium'>PDF</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>XLS</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </Col>
                <Col md={6}></Col>
              </Row>
              <h2 className='text-[#34ACE1] text-[19px] font-medium'>ICC World Cup</h2>
              <div className='flex flex-nowrap overflow-auto mt-2 gap-4 pb-2 mb-4 slimScroll'>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
              </div>
              <h2 className='text-[#34ACE1] text-[19px] font-medium'>ICC World Cup</h2>
              <div className='flex flex-nowrap overflow-auto mt-2 gap-4 pb-2 mb-4 slimScroll'>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
              </div>
              <h2 className='text-[#34ACE1] text-[19px] font-medium'>ICC World Cup</h2>
              <div className='flex flex-nowrap overflow-auto mt-2 gap-4 pb-2 mb-4 slimScroll'>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
              </div>
              <h2 className='text-[#34ACE1] text-[19px] font-medium'>ICC World Cup</h2>
              <div className='flex flex-nowrap overflow-auto mt-2 gap-4 pb-2 mb-4 slimScroll'>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="Tennis">
            <div className="common-table-wrapper m-0">
              <Row className="mb-4">
              <Col md={8}>
                    <div className='flex gap-3'>
                        <label class="relative block">
                            <span class="sr-only">Search</span>
                            <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                                <svg class="h-5 w-5 fill-slate-300" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                            </span>
                            <input class="placeholder:text-gray-300 block !border border-[#144077] py-2.5 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-base bg-[#144077] w-80" placeholder="Search" type="text" name="Search" />
                        </label>
                        <div className="flex gap-3">
                            <Dropdown className='flex'>
                                <Dropdown.Toggle className='!border min-w-[34px] !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                    <span className=""><img src={Filter} alt="filter" className='h-[16px] filer invert' /></span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                    <Dropdown.Item className='text-sm font-medium'>Last day</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>Last 2 days</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>Last week</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>Last 2 weeks</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>Last month</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown className='flex'>
                              <Dropdown.Toggle className='!border min-w-[34px] !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                  <span><img src={Sort} alt="filter" className='h-[14px] filer invert' /></span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                  <Dropdown.Item className='text-sm font-medium'>Option 1</Dropdown.Item>
                                  <Dropdown.Item className='text-sm font-medium'>Option 2</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown className='flex'>
                                <Dropdown.Toggle className='!border !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] [&_span]:hover:!text-white [&_small]:hover:!text-white flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                    <span className='text-slate-300 transition-all'>Download as </span>
                                    <small className='text-[10px] text-slate-300 ml-2 transition-all'>&#x2BC6;</small>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                    <Dropdown.Item className='text-sm font-medium'>PDF</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>XLS</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </Col>
                <Col md={6}></Col>
              </Row>
              <h2 className='text-[#34ACE1] text-[19px] font-medium'>ICC World Cup</h2>
              <div className='flex flex-nowrap overflow-auto mt-2 gap-4 pb-2 mb-4 slimScroll'>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
              </div>
              <h2 className='text-[#34ACE1] text-[19px] font-medium'>ICC World Cup</h2>
              <div className='flex flex-nowrap overflow-auto mt-2 gap-4 pb-2 mb-4 slimScroll'>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
              </div>
              <h2 className='text-[#34ACE1] text-[19px] font-medium'>ICC World Cup</h2>
              <div className='flex flex-nowrap overflow-auto mt-2 gap-4 pb-2 mb-4 slimScroll'>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
              </div>
              <h2 className='text-[#34ACE1] text-[19px] font-medium'>ICC World Cup</h2>
              <div className='flex flex-nowrap overflow-auto mt-2 gap-4 pb-2 mb-4 slimScroll'>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="Basketball">
            <div className="common-table-wrapper m-0">
              <Row className="mb-4">
              <Col md={8}>
                    <div className='flex gap-3'>
                        <label class="relative block">
                            <span class="sr-only">Search</span>
                            <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                                <svg class="h-5 w-5 fill-slate-300" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                            </span>
                            <input class="placeholder:text-gray-300 block !border border-[#144077] py-2.5 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-base bg-[#144077] w-80" placeholder="Search" type="text" name="Search" />
                        </label>
                        <div className="flex gap-3">
                            <Dropdown className='flex'>
                                <Dropdown.Toggle className='!border min-w-[34px] !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                    <span className=""><img src={Filter} alt="filter" className='h-[16px] filer invert' /></span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                    <Dropdown.Item className='text-sm font-medium'>Last day</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>Last 2 days</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>Last week</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>Last 2 weeks</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>Last month</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown className='flex'>
                              <Dropdown.Toggle className='!border min-w-[34px] !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                  <span><img src={Sort} alt="filter" className='h-[14px] filer invert' /></span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                  <Dropdown.Item className='text-sm font-medium'>Option 1</Dropdown.Item>
                                  <Dropdown.Item className='text-sm font-medium'>Option 2</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown className='flex'>
                                <Dropdown.Toggle className='!border !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] [&_span]:hover:!text-white [&_small]:hover:!text-white flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                    <span className='text-slate-300 transition-all'>Download as </span>
                                    <small className='text-[10px] text-slate-300 ml-2 transition-all'>&#x2BC6;</small>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                    <Dropdown.Item className='text-sm font-medium'>PDF</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>XLS</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </Col>
                <Col md={6}></Col>
              </Row>
              <h2 className='text-[#34ACE1] text-[19px] font-medium'>ICC World Cup</h2>
              <div className='flex flex-nowrap overflow-auto mt-2 gap-4 pb-2 mb-4 slimScroll'>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
              </div>
              <h2 className='text-[#34ACE1] text-[19px] font-medium'>ICC World Cup</h2>
              <div className='flex flex-nowrap overflow-auto mt-2 gap-4 pb-2 mb-4 slimScroll'>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
              </div>
              <h2 className='text-[#34ACE1] text-[19px] font-medium'>ICC World Cup</h2>
              <div className='flex flex-nowrap overflow-auto mt-2 gap-4 pb-2 mb-4 slimScroll'>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
              </div>
              <h2 className='text-[#34ACE1] text-[19px] font-medium'>ICC World Cup</h2>
              <div className='flex flex-nowrap overflow-auto mt-2 gap-4 pb-2 mb-4 slimScroll'>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="Rugby">
            <div className="common-table-wrapper m-0">
              <Row className="mb-4">
              <Col md={8}>
                    <div className='flex gap-3'>
                        <label class="relative block">
                            <span class="sr-only">Search</span>
                            <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                                <svg class="h-5 w-5 fill-slate-300" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                            </span>
                            <input class="placeholder:text-gray-300 block !border border-[#144077] py-2.5 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-base bg-[#144077] w-80" placeholder="Search" type="text" name="Search" />
                        </label>
                        <div className="flex gap-3">
                            <Dropdown className='flex'>
                                <Dropdown.Toggle className='!border min-w-[34px] !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                    <span className=""><img src={Filter} alt="filter" className='h-[16px] filer invert' /></span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                    <Dropdown.Item className='text-sm font-medium'>Last day</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>Last 2 days</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>Last week</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>Last 2 weeks</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>Last month</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown className='flex'>
                              <Dropdown.Toggle className='!border min-w-[34px] !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                  <span><img src={Sort} alt="filter" className='h-[14px] filer invert' /></span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                  <Dropdown.Item className='text-sm font-medium'>Option 1</Dropdown.Item>
                                  <Dropdown.Item className='text-sm font-medium'>Option 2</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            
                            <Dropdown className='flex'>
                                <Dropdown.Toggle className='!border !border-[#144077] bg-[#144077] hover:!border-[#FF6600] hover:bg-[#FF6600] focus:!bg-[#FF6600] [&_span]:hover:!text-white [&_small]:hover:!text-white flex items-center px-[.7rem] !rounded-none' id="dropdown-basic">
                                    <span className='text-slate-300 transition-all'>Download as </span>
                                    <small className='text-[10px] text-slate-300 ml-2 transition-all'>&#x2BC6;</small>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='shadow border-0 rounded-xl'>
                                    <Dropdown.Item className='text-sm font-medium'>PDF</Dropdown.Item>
                                    <Dropdown.Item className='text-sm font-medium'>XLS</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </Col>
                <Col md={6}></Col>
              </Row>
              <h2 className='text-[#34ACE1] text-[19px] font-medium'>ICC World Cup</h2>
              <div className='flex flex-nowrap overflow-auto mt-2 gap-4 pb-2 mb-4 slimScroll'>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
              </div>
              <h2 className='text-[#34ACE1] text-[19px] font-medium'>ICC World Cup</h2>
              <div className='flex flex-nowrap overflow-auto mt-2 gap-4 pb-2 mb-4 slimScroll'>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
              </div>
              <h2 className='text-[#34ACE1] text-[19px] font-medium'>ICC World Cup</h2>
              <div className='flex flex-nowrap overflow-auto mt-2 gap-4 pb-2 mb-4 slimScroll'>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
              </div>
              <h2 className='text-[#34ACE1] text-[19px] font-medium'>ICC World Cup</h2>
              <div className='flex flex-nowrap overflow-auto mt-2 gap-4 pb-2 mb-4 slimScroll'>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
                <div className='bg-[#0D2C53] p-3 rounded-[18px] text-white'>
                  <div className='flex items-center'>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png" className='object-cover w-full h-full' />
                      </div>
                      <div>India</div>
                    </div>
                    <div className='text-[#272727] opacity-30 mx-5'>Vs</div>
                    <div className='flex items-center font-semibold'>
                      <div className='w-9 h-8 rounded-md overflow-hidden bg-[#D2D2D2] mr-2 flex items-center'>
                        <img  src="https://cdn.britannica.com/33/1733-050-04264811/FLAG-Ireland.jpg" className='object-cover w-full h-full' />
                      </div>
                      <div>Ireland</div>
                    </div>
                  </div>
                  <div className='text-[13px] font-medium mt-4 text-[#ffffff] opacity-60'>100/200 Parameters Enabled</div>
                </div>
              </div>
            </div>
          </Tab.Pane>
        </Tab.Content>

      </Tab.Container>
      <Modal dialogClassName="modal-custom-sm" centered show={delet} onHide={deleteClose}>
        <Modal.Body className='p-0'>
          <div className="card logout-card common_box">
            <div className="card-body common_box_body py-4 px-3">
              <p className="modalTitle text-center mb-2">Are you sure you want to<br />delete this User?</p>
              <form className="custom_form">
                <div className="btn-wrapper text-center">
                  <Link className="btn btn-form btn-cancel" onClick={deleteClose}>No</Link>
                  <Link className="btn btn-form btn-add" onClick={deleteClose}>Yes</Link>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal dialogClassName="modal-custom-sm" centered show={block} onHide={blockClose}>
        <Modal.Body className='p-0'>
          <div className="card logout-card common_box">
            <div className="card-body common_box_body py-4 px-3">
              <p className="modalTitle text-center mb-2">Are you sure you want to<br />block this User?</p>
              <form className="custom_form">
                <div className="btn-wrapper text-center">
                  <Link className="btn btn-form btn-cancel" onClick={blockClose}>No</Link>
                  <Link className="btn btn-form btn-add" onClick={blockClose}>Yes</Link>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size='sm'
        centered
        show={rejection}
        onHide={rejectionClose}
      >
        <Modal.Body className="p-0">
          <div className="card logout-card common_box">
            <div className="card-body p-0">
              <Form className="custom_form">
                <div className="py-4 px-4">
                  <p className="text-lg font-semibold text-center mb-3">
                    Enter your reason for rejection
                  </p>

                  <Form.Group className="mb-3 cf_label" controlId="Entry Fee">
                    <Form.Control
                      className="cf_input rounded-lg"
                      as="textarea" rows={4}
                      placeholder="Write your reason..."
                      value=""
                    />
                  </Form.Group>

                  <div className="btn-wrapper text-center gap-3">
                    <Link className="btn btn-form btn-cancel rounded-xl w-50" onClick={rejectionClose}>Cancel</Link>
                    <Link className="btn btn-form btn-add rounded-xl w-50" onClick={rejectionClose}>Submit</Link>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal></> )}
    </>
  );
}

export default BetCreation;
