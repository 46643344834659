import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import './NotificationManagement.css'; // Custom styles

const Noticemanagement = () => {
  const [showModal, setShowModal] = useState(false); // Control modal visibility
  const [userType, setUserType] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [notificationText, setNotificationText] = useState('');
  const [searchTerm, setSearchTerm] = useState(''); // Search term

  const users = [
    { id: 1, userId: '1234', name: 'Khaled Anwar', email: 'roberto12@gmail.com', date: '12 July 2024', complaint: 'Lorem ipsum dolor sit amet...' },
    { id: 2, userId: '1234', name: 'John Smit', email: 'roberto12@gmail.com', date: '12 July 2024', complaint: 'Lorem ipsum dolor sit amet...' },
    { id: 3, userId: '1234', name: 'John Smit', email: 'roberto12@gmail.com', date: '12 July 2024', complaint: 'Lorem ipsum dolor sit amet...' },
    { id: 4, userId: '1234', name: 'John Smit', email: 'roberto12@gmail.com', date: '12 July 2024', complaint: 'Lorem ipsum dolor sit amet...' },
    { id: 5, userId: '1234', name: 'John Smit', email: 'roberto12@gmail.com', date: '12 July 2024', complaint: 'Lorem ipsum dolor sit amet...' },
    { id: 6, userId: '1234', name: 'John Smit', email: 'roberto12@gmail.com', date: '12 July 2024', complaint: 'Lorem ipsum dolor sit amet...' },
    { id: 7, userId: '1234', name: 'John Smit', email: 'roberto12@gmail.com', date: '12 July 2024', complaint: 'Lorem ipsum dolor sit amet...' },
    { id: 8, userId: '1234', name: 'John Smit', email: 'roberto12@gmail.com', date: '12 July 2024', complaint: 'Lorem ipsum dolor sit amet...' },];

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="app-container">
      {/* Search Bar */}
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="send-notification-btn" onClick={handleOpenModal}>
          +Send new notification
        </button>
      </div>

      {/* User Table */}
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>User ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Notification</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user, index) => (
              <tr key={user.id} style={index % 2 === 0 ? styles.rowOdd : styles.rowEven}>
                <td>{index + 1}</td>
                <td>{user.userId}</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.complaint}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Notification Modal */}
      <Modal show={showModal} onHide={handleCloseModal}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ backgroundColor: '#FFFFFF' }}
        >
          <Form>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
              {/* First Dropdown */}
              <Form.Group style={{ flex: 1, marginRight: '10px' }} controlId="userType">
                <Form.Label>Choose user type</Form.Label>
                <Form.Control
                  as="select"
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                >
                  <option>Select one</option>
                  <option>Admin</option>
                  <option>User</option>
                </Form.Control>
              </Form.Group>

              {/* Second Dropdown */}
              <Form.Group style={{ flex: 1, marginLeft: '10px' }} controlId="userType2">
                <Form.Label>Choose user(s)</Form.Label>
                <Form.Control
                  as="select"
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                >
                  <option>Select</option>
                  <option>User 1</option>
                  <option>User 2</option>
                </Form.Control>
              </Form.Group>
            </div>

            {/* Textarea */}
            <Form.Group className="mb-3" controlId="notificationText">
              <Form.Label>Your notification text</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Type here"
                value={notificationText}
                onChange={(e) => setNotificationText(e.target.value)}
              />
            </Form.Group>
          </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleCloseModal} style={styles.cancelButton}>
            Cancel
          </Button>
          <Button variant="dark" onClick={handleCloseModal} style={styles.sendButton}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const styles = {
  rowOdd: {
    backgroundColor: '#f9f9f9',
  },
  rowEven: {
    backgroundColor: '#fff',
  },
  cancelButton: {
    backgroundColor: '#e0e0e0',
    color: '#333',
    borderColor: '#e0e0e0',
  },
  sendButton: {
    backgroundColor: '#000',
    color: '#fff',
    borderColor: '#000',
  },
};

export default Noticemanagement;
