import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import Spinnerr from "../../Components/Spinner/Spinner";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Sort from '../../assets/image/filter.png'
import Filter from '../../assets/image/filter.png'
import Dummy from '../../assets/image/user.png'
import Pagination from '../../Components/Pagination/Pagination';
import 'jspdf-autotable';
import Tab from 'react-bootstrap/Tab';
import {
  ListAllCredits,
  UpdateCredit,
} from "../../ApiComponent/CreditsManagement";
import { toast } from "react-toastify";
const CreditsManagement = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [btstate,setbtstate]=useState(false)
  const totalPage = 2;
  const [page, setPage] = useState(0);
  const navigate=useNavigate()
  const [allCredits, setAllCredits] = useState();

  const [del, setDel] = useState({
    value: false,
    type: "",
    reward: "",
    id: "",
  });
  const delClose = () => setDel({ value: false, type: "", reward: "", id: "" });
  const delShow = (data) => {
    setDel({
      value: true,
      type: data?.type,
      reward: data?.reward,
      id: data?.id,
    });
  };

  const [block, setBlock] = useState(false);
  const blockClose = () => setBlock(false);
  const blockShow = () => setBlock(true);

  const handleCredList = async (e) => {
    try {
      const res = await ListAllCredits();
console.log(res?.data?.data,"================DAta credit")
      if (res?.status === 200) {
        setAllCredits(res?.data?.data);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log(error?.response?.data?.code)
      if(error?.response?.data?.code===401){
       localStorage.clear("token")
       toast.error(error?.response?.data?.errorMessage)
       navigate("/")
      
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

  // Check if the reward is zero
  if (del?.reward === "" || del?.reward === "0" || Number(del?.reward) === 0) {
    toast.error("Reward field can't be zero.");
    return; // Exit the function early
  }
 
    setbtstate(true)
    // if (del.reward===0) {
    //   toast.error("Please enter reward cash");
    //   return;
    // }
    const credDetails = {
      reward_cash: del?.reward,
      credit_type: del?.type,
      credit_id: del?.id,

    };
    
    try {
      const res = await UpdateCredit(credDetails);

      if (res?.status === 200) {
        handleCredList();
        delClose();
        toast.success("Reward updated successfully!");
        setbtstate(false)
      } else {
        toast.error("Something went wrong");
        setbtstate(false)
      }
    } catch (error) {
      toast.error("Reward Field Can't Be Blank");
      setbtstate(false)
    }
  };

  useEffect(() => {
    handleCredList();
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    
    setDel((prevState) => ({ ...prevState, reward: e.target.value }));
  };


  const [doctors, setDoctors] = useState([
    {
      id: 1,
      doctorId: '1234',
      name: 'Dr. Khaled Anwar',
      mobile: '+123456789',
      email: 'roberto12@gmail.com',
      availableDays: 'Mon-Fri',
      availableHours: '9AM - 6PM',
      appointmentsRescheduled: 0,
      cancelledAppointments: 0,
    },
    {
      id: 2,
      doctorId: '1234',
      name: 'Dr. John simit',
      mobile: '+123456789',
      email: 'roberto12@gmail.com',
      availableDays: 'Mon-Fri',
      availableHours: '9AM - 6PM',
      appointmentsRescheduled: 1,
      cancelledAppointments: 1,
    },
    {
      id: 3,
      doctorId: '1234',
      name: 'Dr. Karda deo',
      mobile: '+123456789',
      email: 'roberto12@gmail.com',
      availableDays: 'Mon-Fri',
      availableHours: '9AM - 6PM',
      appointmentsRescheduled: 22,
      cancelledAppointments: 22,
    },
    {
      id: 4,
      doctorId: '1234',
      name: 'Dr. Karda deo',
      mobile: '+123456789',
      email: 'roberto12@gmail.com',
      availableDays: 'Mon-Fri',
      availableHours: '9AM - 6PM',
      appointmentsRescheduled: 22,
      cancelledAppointments: 22,
    },
    {
      id: 5,
      doctorId: '1234',
      name: 'Dr. Karda deo',
      mobile: '+123456789',
      email: 'roberto12@gmail.com',
      availableDays: 'Mon-Fri',
      availableHours: '9AM - 6PM',
      appointmentsRescheduled: 22,
      cancelledAppointments: 22,
    },
    {
      id: 6,
      doctorId: '1234',
      name: 'Dr. Karda deo',
      mobile: '+123456789',
      email: 'roberto12@gmail.com',
      availableDays: 'Mon-Fri',
      availableHours: '9AM - 6PM',
      appointmentsRescheduled: 22,
      cancelledAppointments: 22,
    },
    // Add more rows if needed...
  ]);

  useEffect(() => {
    // Fetch data from API in future updates
  }, []);

  // Inline styling for responsiveness and layout
  const styles = {
    container: {
      width: '100%',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px',
    
    },
    tableWrapper: {
      width: '100%',
      overflowX: 'auto',

    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      marginBottom: '20px',
      
    },
    thTd: {
      padding: '12px 15px',
      textAlign: 'left',
      borderBottom: '1px solid #ddd',
      
    },
    thTd2: {
      padding: '12px 15px',
      textAlign: 'left',
      // borderBottom: '1px solid #ddd',
    },
    th: {
      backgroundColor: '#fff',
    },
    tableHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '20px',
    },
    search: {
      padding: '10px',
      width: '200px',
    },
    button: {
      padding: '10px 20px',
      marginLeft: '10px',
      cursor: 'pointer',
    },
    
    // Alternating background color
    rowEven: {
      backgroundColor: '#f9f9f9', // Light grey for even rows
    },
    rowOdd: {
      backgroundColor: '#fff', // White for odd rows
    },
    // Media query styles for responsiveness
    mediaQuery: `
      @media screen and (max-width: 768px) {
        .tableHeader {
          flex-direction: column;
          align-items: flex-start;
        }
        .thTd {
          font-size: 14px;
        }
        .pagination {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    `,
  };


  return (
    <>
      {loading ? (
        <Spinnerr />
      ) : error ? (
        <h1 className=" text-4xl font-sfpdBold text-white text-center">
          SOME ERROR OCCURED
        </h1>
      ) : (
        <>
      <div style={styles.container}>
      {/* Adding a style tag for media queries */}
      <style>{styles.mediaQuery}</style>
      <div style={styles.tableWrapper}>
      <table style={styles.table}>
  <thead style={{ backgroundColor: '#fff' }}>
    <tr>
      <th style={styles.thTd}>Doctor ID</th>
      <th style={styles.thTd}>Name</th>
      <th style={styles.thTd}>Mobile</th>
      <th style={styles.thTd}>Email</th>
      <th style={styles.thTd}>Available Days</th>
      <th style={styles.thTd}>Available Hours</th>
      <th style={styles.thTd}>Appointments Rescheduled</th>
      <th style={styles.thTd}>Cancelled Appointments</th>
    </tr>
  </thead>
  <tbody>
    {doctors.map((doctor, index) => (
      <tr key={doctor.id} style={index % 2 === 0 ? styles.rowOdd : styles.rowEven}>
        <td style={styles.thTd2}>{doctor.doctorId}</td>
        <td style={styles.thTd2}>{doctor.name}</td>
        <td style={styles.thTd2}>{doctor.mobile}</td>
        <td style={styles.thTd2}>{doctor.email}</td>
        <td style={styles.thTd2}>{doctor.availableDays}</td>
        <td style={styles.thTd2}>{doctor.availableHours}</td>
        <td style={styles.thTd2}>{doctor.appointmentsRescheduled}</td>
        <td style={styles.thTd2}>{doctor.cancelledAppointments}</td>
      </tr>
    ))}
  </tbody>
</table>

<div style={styles.pagination}>
          <span style={styles.paginationSpan}>8 of 100 results</span>
          <button style={styles.paginationButton}>&lt;</button>
          <button style={styles.paginationButton}>&gt;</button>
        </div>
      </div>
    </div>
          <Modal
            dialogClassName="max-w-[390px]"
            centered
            show={del?.value}
            onHide={delClose}
          >
            <Modal.Body className="p-0">
              <div className="card common_box !rounded-sm px-3">
                <div className="card-body common_box_body py-4 px-3">
                  <h2 className="text-3xl text-center">Edit credit</h2>
                  <p className="text-center mb-2 mt-1 text-lg text-white">
                    {del?.type}
                  </p>
                  <form className="custom_form">
                    <div class="relative my-4">
                      <div class="absolute inset-y-0 end-4 flex items-center ps-3.5 pointer-events-none font-semibold text-[#000000] opacity-40 text-lg">
                        $
                      </div>
                      <input
                        type="number"
                        id=""
                        value={del?.reward}
                        class="bg-gray-10 border border-gray-300 rounded-md font-normal block w-full pe-10 p-2.5 py-2.5 text-lg focus:outline-none text-black"
                        placeholder="Enter cash"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="btn-wrapper text-center gap-3">
                      <Link
                        className="btn btn-form btn-cancel !w-full"
                        onClick={delClose}
                      >
                        Cancel
                      </Link>
                      <button
                        className="btn btn-form btn-add !w-full"
                        disabled={btstate}
                        onClick={handleUpdate}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Modal.Body>
          </Modal>{" "}
        </>
      )}
    </>
  );
};

export default CreditsManagement;