import axios from "axios";
import { baseUrl } from "../config/Base";
export const UserList = async (searchQuery) => {
  const token = localStorage.getItem("token");
  console.log(searchQuery, " Data is Coming Broooo")
  try {
    if (searchQuery.search) {

      return await axios.get(`${baseUrl}getAllusers?role=${searchQuery.role}&page=${searchQuery.page}&limit=${searchQuery.limit}&searchQuery=${searchQuery.search}&filterDays=${searchQuery.filterday}`, {
        headers: {
          authorization: `${token}`,
        },
      }
      );
    }

    else {
      return await axios.get(`${baseUrl}getAllusers?role=${searchQuery.role}&page=${searchQuery.page}&limit=${searchQuery.limit}&filterDays=${searchQuery.filterday}`, {
        headers: {
          authorization: `${token}`,
        },
      });
    }
  } catch (error) {

  }


};
export const updateStatus = async (userid, status) => {
  console.log(userid, status, "data==============data.data.status=======>")
  const user = {
    userId: userid,
    status: status
  }


  const token = localStorage.getItem("token");
  try {
    return await axios.put(`${baseUrl}updateStatus`, user, {
      headers: {
        authorization: `${token}`,
      },
    });

  } catch (error) {

  }

}

export const userview = async (id) => {
  const token = localStorage.getItem("token");
  try {
    return await axios.get(`${baseUrl}getUserDetails/${id}`, {
      headers: {
        authorization: `${token}`,
      },
    });

  } catch (error) {

  }

}

export const deleteUser = async (userid) => {

  console.log(userid, "data==============data.data.status=======>")

  const token = localStorage.getItem("token");
  try {
    return await axios.delete(`${baseUrl}deleteuser/${userid}`, {
      headers: {
        authorization: `${token}`,
      },
    });

  } catch (error) {

  }

}

export const addNewUser = async (userid) => {

  console.log(userid, "data==============data.data.status=======>")

  const token = localStorage.getItem("token");
  try {
    return await axios.post(`${baseUrl}add-user`, userid, {
      headers: {
        authorization: `${token}`,
      },
    });

  } catch (error) {

  }

}

export const editUser = async (id,userdata) => {

  console.log(userdata,id, "data==============edituser=======>")

  const token = localStorage.getItem("token");
  try {
    return await axios.put(`${baseUrl}edit-user/${id}`, userdata, {
      headers: {
        authorization: `${token}`,
      },
    });

  } catch (error) {

  }

}

