import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { ChangePasswordApi } from "../../ApiComponent/ChangePasswordApi";
import { ToastContainer, toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import { addNewUser, editUser, userview } from "../../ApiComponent/UserManagment";


const EditUser = () => {
    const location = useLocation();
    const id = location.pathname.split("/")[2];
console.log(id)
    const navigate = useNavigate()
    const [username, setUserName] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    // const [password, setPassword] = useState('');

    // const showPassoword = () => setPassword(!password);
    const [update, setUpdate] = useState(false);
    const updateClose = () => setUpdate(false);

    let userData = {
        "user_name": username,
        "full_name": name,
        "email": email,
        // "password": password,
        "city": city
    }

    const userviewfunction = () => {

        try {
            userview(id)
                .then(res => {

                    if (res?.data?.code === 200) {
                        
                        setUserName(res.data.data.user_name);
                        setName(res?.data?.data?.full_name)
                        setCity(res?.data?.data?.city)
                        setEmail(res.data.data.email);
                    }
                })

        } catch (error) {
            // setLoading(false)
            // setError(true)
        }
    }

    useEffect(() => {
        userviewfunction()
    }, [id])
    const handleSubmit = async (e) => {

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        e.preventDefault();
        if (username.length === 0) {
            toast.error("User Name Required");
            return;
        }
        if (name.length === 0) {
            toast.error("Full Name Required");
            return;
        }
        if (email.length === 0) {
            toast.error("Email Required");
            return;
        }
        if (!emailRegex.test(email)) {
            toast.error("Enter correct email");
            return;
        }
        if (city.length === 0) {
            toast.error("City Required");
            return;
        }
        // if (email.length === 0 && password.length === 0) {
        //     toast.error("Email And Password Are Required");
        //     return;
        // }

        // if (password.length === 0) {
        //     toast.error("Password Required");
        //     return;
        // }

        try {
            const res = await editUser(id,userData);
            console.log(res, "Response ");
            if (res?.status === 200) {
                // localStorage.clear("token", "userData")
                toast.success("User Added successfully!");
                navigate("/user-management")
            } else {
                toast.error("Something went wrong");
            }
        } catch (error) {
            toast.error(error?.response?.data?.errorMessage);
            console.log(error, "error ");
        }
    };

    const handleBackClick = (event) => {
        event.preventDefault();
        navigate(-1); // -1 will take the user back to the previous page
    };


    return (
        <>
            <Row>
                <Col xl={2}>
                    <Link onClick={handleBackClick} className="border-[1px] px-4 py-1 inline-block text-[black] border-[black]  transition-all">
                        Back
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col xl={5} className="mx-auto mt-0">
                    <div className=" text-black p-4 [&_p]:mb-2 ">
                        <form className="custom_form">
                            <div className="form-group">
                                <div class="relative">
                                    <p className="text-xs text-[#9f9f9f] ">User Name</p>
                                    <input
                                        type="user name"
                                        class="block px-2.5 py-2 w-full text-base text-black bg-transparent  outline-1 outline-double outline-gray-400  dark:text-white dark:border-gray-400 dark:focus:border-blue-500 focus:outline-1 focus:ring-0 focus:outline-gray peer"
                                        value={username}
                                        onChange={(e) => setUserName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div class="relative">
                                    <p className="text-xs text-[#9f9f9f] ">Full Name</p>
                                    <input
                                        type="name"
                                        class="block px-2.5 py-2 w-full text-base text-black bg-transparent  outline-1 outline-double outline-gray-400  dark:text-white dark:border-gray-400 dark:focus:border-blue-500 focus:outline-1 focus:ring-0 focus:outline-gray peer"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group !mt-6">
                                <div class="relative">
                                    <p className="text-xs text-[#9f9f9f] ">Enter Email</p>
                                    <input
                                        type="email"
                                        class="block px-2.5 py-2 w-full text-base text-black bg-transparent  outline-1 outline-double outline-gray-400  dark:text-white dark:border-gray-400 dark:focus:border-blue-500 focus:outline-1 focus:ring-0 focus:outline-gray peer"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group !mt-6">
                                <div class="relative">
                                    <p className="text-xs text-[#9f9f9f] ">City</p>
                                    <input
                                        type="city"
                                        class="block px-2.5 py-2 w-full text-base text-black bg-transparent  outline-1 outline-double outline-gray-400  dark:text-white dark:border-gray-400 dark:focus:border-blue-500 focus:outline-1 focus:ring-0 focus:outline-gray peer"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                    />
                                </div>

                            </div>
                            {/* <div className="form-group !mt-6">
                                <div class="relative">
                                    <p className="text-xs text-[#9f9f9f] ">Enter Password</p>
                                    <input
                                        type={password ? "text" : "password"}
                                        class="block px-2.5 py-2 w-full text-base text-black bg-transparent  outline-1 outline-double outline-gray-400  dark:text-white dark:border-gray-400 dark:focus:border-blue-500 focus:outline-1 focus:ring-0 focus:outline-gray peer"
                                        id="pwd3"
                                        placeholder=""
                                        name="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <div
                                        className="pos-abs hide-show-pass mt-[12px]"
                                        onClick={showPassoword}
                                    >
                                        {password ? <FaEye /> : <FaEyeSlash />}
                                    </div>
                                </div>

                            </div> */}

                            <button

                                onClick={(e) => handleSubmit(e)}
                                className="text-white w-full bg-[#1d1d1c] outline-none focus:outline-none focus:ring-0 font-medium  text-xs px-5 py-2.5 text-center me-2 mb-2  block !mt-8"
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </Col>
            </Row>
            {/* <Modal dialogClassName="modal-custom-sm" centered show={update} onHide={updateClose}>
                <Modal.Body className='p-0'>
                    <div className="card logout-card common_box">
                        <div className="card-body common_box_body py-4 px-3">
                            <p className="modalTitle text-center mb-2">Are you sure you want to<br />change password?</p>

                            <div className="btn-wrapper text-center">
                                <button className="btn btn-form btn-cancel" onClick={updateClose}>No</button>
                                <button className="btn btn-form btn-add" onClick={handleSubmit}>Yes</button>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal> */}
        </>
    );
};

export default EditUser;
