import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Edit from '../../assets/image/edit.png'
import Spinnerr from "../../Components/Spinner/Spinner";
import JoditEditor from "jodit-react";
import { GetCMsByType, UpdateCMS } from '../../ApiComponent/CMSApi';
import { toast } from 'react-toastify';

function ALM_policy() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState("");
    const [id, setId] = useState("")
    const [newData, setNewData] = useState({
        id: "",
        title: "Terms And Condition",
        description: ""
    });
    const type = {
        type: "4"
    }
    const [isEditing, setIsEditing] = useState(false);
    const validate=()=>{
        if(newData.description.length<=0){
          toast.error("Terms And Conditions Can't Be Blank")
        }
    }

    useEffect(() => {
        getData()
       

    }, [])

    const getData = () => {
        setLoading(true);
        GetCMsByType(type)
            .then((res) => {
                if (res?.status === 200) {
                    const decodedDescription = decodeHtmlEntities(res?.data?.data?.description);
                    setLoading(false);
                    setData(decodedDescription);
                    setId(res?.data?.data?._id);
                } else {
                    throw new Error('Failed to fetch data');
                }
            })
            .catch((err) => {
                setLoading(false);
                setError(true);
                console.error("API fetch error:", err);
            });
    };
    function decodeHtmlEntities(str) {
        const txt = document.createElement('textarea');
        txt.innerHTML = str;
        return txt.value;
    }

    const HtmlRenderer = (htmlContent) => {
        const decodedHtml = decodeHtmlEntities(htmlContent);

        return (
            <div className=' text-slate-200' dangerouslySetInnerHTML={{ __html: decodedHtml }} />
        );
    };
    const handleSave = () => {
        if(newData.description.length<=0){
            toast.error("ALM Policy Can't Be Blank..")
            setIsEditing(true)
        }
        else if(newData.description.length>0){
        setLoading(true);
        UpdateCMS(newData)
            .then((res) => {
                if (res.status === 200) {
                    getData();
                    setLoading(false);
                    setIsEditing(false);
                    toast.success("ALM_policy Updated");
                } else {
                    throw new Error('Failed to update data');
                }
            })
            .catch((err) => {
                setLoading(false);
                setError(true);
                toast.error("An error occurred while updating data");
                console.error("API update error:", err);
            });
        }
       
    };


    return (
        <>
            {loading ? (
                <Spinnerr />
            ) : error ? (
                <h1 className=" text-4xl font-sfpdBold text-white text-center">SOME  ERROR  OCCURED</h1>
            ) : (
                <div>
                    {isEditing ? (
                        <>

                            <div className="card-head p-0 text-end gap-2 flex justify-end mb-4">
                            <Link
                                    href="#"
                                    className="bg-blue-500 px-5 py-2 rounded-lg  text-gray-50 "
                                    onClick={() => setIsEditing(false)} // Cancel editing
                                >
                                    Cancel
                                </Link>
                                <Link
                                    href="#"
                                    className=" px-5 py-2 rounded-lg  bg-orange-600 text-gray-50"
                                    onClick={handleSave} // Save changes
                                >
                                    Save
                                </Link>
                            </div>
                            <JoditEditor
                                className="textji"
                                value={data}
                                onChange={(text) => setNewData({ ...newData, description: text, id: id })}
                            />
                        </>
                    ) : (
                        <>
                            <Link onClick={() => setIsEditing(true)} className='absolute right-3 -top-12  rounded-full w-9 h-9 bg-[rgba(255,255,255,.3)] inline-flex items-center !border-[#000000] justify-center overflow-hidden border cursor-pointer'>

                                <img src={Edit} className='w-4 h-4'
                                />
                            </Link>
                            {/* <p className="textji">{data}</p> */}
                            <div className='bg-[#144077] text-white p-5 [&_p]:mb-2 '  >{HtmlRenderer(data)}</div>




                        </>
                    )}
                </div>
            )}

        </>
    )
}

export default ALM_policy